import React, { useEffect, useRef } from 'react'
import { css } from '@emotion/core'
import Link from '../link'
import Img from 'gatsby-image'
import theme from '../../../config/theme'
import { rhythm } from '~lib/typography'
import styles from './styles'
import Markdown from 'react-markdown'

import studioBanner from '../../images/studio-banner.png'
import teches from './teches'

const btnStyleTable = {
  darkBlue: ['#103268', '#a0c3ff', '#1257bd', 'white'],
  lightYellow: ['#fee5b6', '#CF8700', '#ffc14b', 'white'],
}

function StudioProjectEntry({ index, title, description, url, tech, banner, date, titleColor, btnStyle = 'darkBlue', contentPadding }) {
  // const ref = useRef()
  // useEffect(() => {
  //   async function renderCanvas() {
  //     await showStudioCard(title, description, tech, banner, canvas => {
  //       ref.current.appendChild(canvas)
  //     })
  //   }
  //   renderCanvas()
  // }, [])
  const btnColors = btnStyleTable[btnStyle] || btnStyleTable.darkBlue

  return (
    <div
      css={css`
          background: var(--bgcolor-post);
          padding-bottom: 114.444%;
          margin-bottom: 10px;
          position: relative;
          border-radius: 6px;
          overflow: hidden;
        `}
    >
      {banner ? <Img
        css={css`
            position: absolute !important;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            background:var(--color-line);
          `}
        imgStyle={{ objectFit: 'cover' }}
        fluid={banner.childImageSharp.fluid}
        alt={title}
      /> : <img
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width:100%;
            height:100%;
            background:var(--color-line);
            object-fit: cover;
            width: 100%;
          `}
          src={studioBanner}
          alt={tech}
        />}
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          position: absolute;
          top: 0;
          left: 0;
          height:100%;
          width: 100%;
        `}>
        <div style={{ padding: contentPadding }} css={css`
              padding: 7% 10% 0;
              flex: auto;
              text-align: center;
            `}>
          <h1 style={{ color: titleColor }} css={css`
              // color: var(--text-color-body);
              font-size: 23px;
              margin-bottom: 0;
            `}>{title}</h1>
          {description && (
            <Markdown
              css={css`
              p {
                color: ${titleColor};
                margin-top: ${rhythm(0.5)};
              }
              font-size: 15px;
              overflow: hidden;
              min-height: 80px;
              opacity: .8;
            `}
              style={{ color: titleColor }}
            >
              {description}
            </Markdown>
          )}
          <Link
            to={url}
            css={css`
              position: relative;
              display: block;
              width: 85px;
              margin: 0 auto 10px;
              padding: 8px 5px;
              font-size: 13px;
              border-radius: 20px;
              overflow: hidden;
              background: ${btnColors[0]};
              color: ${btnColors[1]};
              &:hover{
                background: ${btnColors[2]};
                color: ${btnColors[3]};
              }
              svg {
                vertical-align: baseline;
              }
            `}
          >
            查看&nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9"><polyline fill="none" stroke="currentColor" strokeWidth="2" points="57 18 60.5 21.5 57 25" transform="translate(-56 -17)"></polyline></svg>
          </Link>
        </div>
        {/* <div
            css={css`
            flex: 60px 0 0;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
          >
            {/ <span>{date}</span> /}
            <img src={teches[tech].icon} alt={tech} />
          </div> */}
      </div>
    </div>
  )
}

export default StudioProjectEntry

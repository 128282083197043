import React from 'react'
import { graphql } from 'gatsby'
import { bpMaxMD, bpMaxSM } from '~lib/breakpoints'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Container from '../components/container'
import { rhythm } from '~lib/typography'
import SEO from '../components/seo'
import theme from '../../config/theme'
import Hero from '../components/big-hero'
import { uniq, includes, truncate } from 'lodash'

import StudioProjectEntry from '../components/studio/studio-project-entry'

export default function RemoteStudioPage(props) {
  return <RemoteStudio {...props} />
}

function RemoteStudio({ data: { studio } }) {
  return (
    <Layout
      hero={
        <Hero
          title="🚧 工具 / 项目"
          text="用代码堆砌世界的 Coder 来说，任何一个小想法都可能满怀激情的熬夜动手写出来，这里作为记录这些有趣的想法的自留地。"
          bigEmoji="🚧"
          noImage={true}
        />
      }
      headerColor={theme.colors.white}
    >
      <SEO />
      <Container noVerticalPadding>
        <div
          css={css`
            text-align: center;
            margin: 1rem 0 1rem 0;
          `}
        >
          <p
            css={css`
              color: #bdbdbd;
              font-size: 14px;
            `}
          >
            [PS：有一些没有完成或者因为时间和精力搁置的~]
          </p>
        </div>
        <div
          css={css`
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(auto-fill, minmax(301px, 1fr));
          `}
        >
          {studio.edges.map(({ node: studioProject }, index) => (
            <StudioProjectEntry
              index={index}
              key={studioProject.id}
              title={studioProject.frontmatter.title}
              description={truncate(studioProject.frontmatter.description, {
                length: 190,
              })}
              contentPadding={studioProject.frontmatter.contentPadding}
              titleColor={studioProject.frontmatter.titleColor}
              btnStyle={studioProject.frontmatter.btnStyle}
              date={studioProject.frontmatter.date}
              banner={studioProject.frontmatter.banner}
              url={
                studioProject.fields.slug
                  ? studioProject.fields.slug
                  : `/studio/${studioProject.frontmatter.slug}`
              }
              tech={studioProject.frontmatter.tech}
            />
          ))}
        </div>
      </Container>
    </Layout>
  )
}

export const remoteStudioQuery = graphql`
  query {
    studio: allMdx(
      filter: {
          frontmatter: {published: {ne: false}},
          fields: {isStudio: {eq: true}}
      }
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date
            description
            tech
            slug
            titleColor
            btnStyle
            contentPadding
            banner {
              ...bannerImage720
            }
          }
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`
